import { Tree, Badge, Button, Divider, Row, Col } from 'antd';
import OmitBy from 'lodash/omitBy';
import Mem from 'mem';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SplitPane from '@/src/components/SplitPane';
import { RightOutlined, DownOutlined } from '@ant-design/icons';
import Icon from '@/src/components/Icon';
import { Link } from 'react-router-dom';
import PageHeading from '@/src/components/PageHeading';
import { CrudProvider } from '@/src/hooks/crud';
import TimelinePicker from '@/src/components/pickers/TimelinePicker';
import {
  getAllEventsApi,
  getCategoriesApi,
  getFimEventChartApi,
  getFimEventChartWithCategoryApi
} from '../api/events-api';
import Chart from '@/src/components/widget/views/chart/Chart';
import { VirusTotalLink } from '@/src/components/VirusTotalLink';
import { useAuth } from '@/src/hooks/auth';

export default function FIMEvents() {
  const [type, setType] = useState('Windows');
  const [categories, setCategories] = useState({});
  const [chartData, setChartData] = useState(null);
  const [categoryWiseChartData, setCategoryWiseChartData] = useState(null);
  const [timeline, setTimeline] = useState({
    selected: 'Today'
  });
  const navigate = useNavigate();
  const { formatDateTime } = useAuth();

  useEffect(() => {
    getFimEventChartApi().then((data) => {
      setChartData(data);
    });
  }, []);

  useEffect(() => {
    getFimEventChartWithCategoryApi(type, timeline).then((data) => {
      setCategoryWiseChartData(data);
    });
  }, [timeline, type]);

  useEffect(() => {
    getCategoriesApi(timeline).then((categories) => {
      setCategories(categories);
      if (!type || Object.keys(categories).includes(type) === false) {
        setType(Object.keys(categories)[0]);
      }
    });
    // eslint-disable-next-line
  }, [timeline]);

  function handleTypeSelected(data) {
    setType(data[0]);
  }

  const defaultColumns = [
    {
      title: 'Asset',
      key: 'asset',
      dataIndex: 'asset',
      render({ record }) {
        return <Link to={`/inventory/endpoints/${record.asset_id}`}>{record.host_name}</Link>;
      }
    },
    // {
    //   title: 'Event ID',
    //   dataIndex: 'event_id',
    //   key: 'event_id',
    //   hidden: true,
    //   sortable: false
    // },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Target Path',
      dataIndex: 'target_path',
      key: 'target_path',
      ellipsis: true,
      sortable: false,
      render({ record }) {
        return record.target_path || record.path;
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action'
    },
    // {
    //   title: 'Transaction ID',
    //   dataIndex: 'transaction_id',
    //   key: 'transaction_id'
    // },
    // {
    //   title: 'INODE',
    //   dataIndex: 'inode',
    //   key: 'inode'
    // },
    // {
    //   title: 'User',
    //   dataIndex: 'user_id',
    //   key: 'user_id'
    // },
    // {
    //   title: 'Group',
    //   dataIndex: 'group_id',
    //   key: 'group_id'
    // },
    // {
    //   title: 'Mode',
    //   dataIndex: 'mode',
    //   key: 'mode'
    // },
    // {
    //   title: 'Size',
    //   dataIndex: 'size',
    //   key: 'size'
    // },
    // {
    //   title: 'Last Access Time',
    //   dataIndex: 'last_access_time',
    //   key: 'last_access_time'
    // },
    // {
    //   title: 'Last Modified Time',
    //   dataIndex: 'last_modification_time',
    //   key: 'last_modification_time'
    // },
    // {
    //   title: 'Last Status Change Time',
    //   dataIndex: 'last_status_change_time',
    //   key: 'last_status_change_time'
    // },
    // {
    //   title: 'MD5',
    //   dataIndex: 'md5',
    //   key: 'md5'
    // },
    // {
    //   title: 'SHA1',
    //   dataIndex: 'sha1',
    //   key: 'sha1'
    // },
    // {
    //   title: 'SHA256',
    //   dataIndex: 'sha256',
    //   key: 'sha256'
    // },
    // {
    //   title: 'Hashed',
    //   dataIndex: 'hashed',
    //   key: 'hashed'
    // },
    {
      title: 'Event Time',
      dataIndex: 'event_time',
      key: 'event_time',

      render({ record }) {
        return record.event_time ? formatDateTime(record.event_time * 1000) : '';
      }
    }
  ];

  const excludedKeys = ['asset_id', 'asset_name'];

  const virusTotalLinks = ['md5', 'sha256'];

  const dateTimeKeys = ['event_time'];

  const getTable = Mem(
    (item) => {
      let obj = item;
      obj = OmitBy(obj, (i, key) => i === null || i === '');
      const rows = Object.keys(obj)
        .filter((a) => excludedKeys.includes(a) === false)
        .map((key) => (
          <Row key={key}>
            <Col span={24}>
              <div className="ml-12 flex flex-1">
                <span className="text-primary flex-shrink-0" style={{ width: '150px' }}>
                  {key}
                </span>
                <span className="flex-1 flex-wrap flex break-words">
                  {virusTotalLinks.includes(key) ? (
                    <VirusTotalLink value={obj[key]} />
                  ) : dateTimeKeys.includes(key) ? (
                    formatDateTime(obj[key] * 1000)
                  ) : (
                    obj[key]
                  )}
                </span>
              </div>
            </Col>
          </Row>
        ));
      return rows;
    },
    {
      cacheKey: (arguments_) => `${arguments_[0].id}`
    }
  );

  function navigateToCreate() {
    navigate(`/fim/configuration?zOperation=create`);
  }

  return (
    <SplitPane
      hasMenu={true}
      leftPane={
        <Tree
          className="inventory-tree my-2 inventory-tree-no-switcher"
          blockNode
          defaultSelectedKeys={['Windows']}
          autoExpandParent
          titleRender={(item) => {
            return (
              <div
                className="flex items-center text-label flex-1 justify-between"
                style={{ whiteSpace: 'nowrap' }}>
                <div>
                  {item.type === 'os' && (
                    <Icon name={`platform_${item.key.toLowerCase()}`} className="mr-2 text-lg" />
                  )}
                  {item.title}{' '}
                </div>
                <Badge className="ml-2 count-badge" showZero count={item.count} />
              </div>
            );
          }}
          onSelect={handleTypeSelected}
          treeData={Object.keys(categories).map((item) => ({
            key: item,
            type: 'os',
            title: item,
            count: categories[item]
          }))}
        />
      }
      rightPane={
        <div className="h-full flex flex-col">
          <PageHeading icon="fim" title="FIM Events" />
          <div className="mt-2" style={{ height: '25%' }}>
            <Row gutter={4} className="h-full">
              <Col span={12} className="h-full">
                <div className="px-4 flex flex-col bg-seperator py-2 rounded h-full">
                  <h3 className="text-center">File Integrity Monitoring Trends</h3>
                  <div className="flex-1 min-h-0 flex flex-col">
                    {chartData && (
                      <Chart
                        widget={{
                          type: 'BarChart',
                          'x-axis': 'Event Date, Platform',
                          'y-axis': 'count',
                          widgetProperties: {
                            legendEnabled: true
                          }
                        }}
                        data={{
                          'x-axis': 'Event Date',
                          'y-axis': 'count'
                        }}
                        result={chartData}
                      />
                    )}
                  </div>
                </div>
              </Col>
              <Col span={12} className="h-full">
                <div className="px-4 flex flex-col bg-seperator py-2 rounded h-full">
                  <h3 className="text-center">Category wise Event Count</h3>
                  <div className="flex-1 min-h-0 flex flex-col">
                    {categoryWiseChartData && (
                      <Chart
                        widget={{
                          type: 'BarChart',
                          'x-axis': 'Event Date, Platform',
                          'y-axis': 'count',
                          widgetProperties: {
                            legendEnabled: true
                          }
                        }}
                        data={{
                          'x-axis': 'Event Date',
                          'y-axis': 'count'
                        }}
                        result={categoryWiseChartData}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <Divider className="my-1" />
          <div className="flex flex-1 flex-col min-h-0">
            <CrudProvider
              key={`${type}-${(timeline || {}).selected || ''}`}
              hasSearch={true}
              beforeCreateSlot={() => (
                <div className="mr-2">
                  <TimelinePicker value={timeline} onChange={setTimeline} />
                </div>
              )}
              createSlot={() => (
                <Button className="mr-2" type="primary" ghost onClick={() => navigateToCreate()}>
                  Configure FIM
                </Button>
              )}
              expandable={{
                expandedRowRender: (record) => (
                  <Row>
                    <Col span={3}></Col>
                    <Col span={21}>{getTable(record)}</Col>
                  </Row>
                ),
                rowExpandable: (record) => true,
                expandIcon: ({ expanded, onExpand, record }) => (
                  <span className="text-label">
                    {expanded ? (
                      <DownOutlined className="text-label" onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <RightOutlined className="text-label" onClick={(e) => onExpand(record, e)} />
                    )}
                  </span>
                )
              }}
              fetchFn={(...args) => getAllEventsApi(...args, { type, timeline })}
              columns={defaultColumns}
            />
          </div>
        </div>
      }
    />
  );
}
