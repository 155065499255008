import { Link } from 'react-router-dom';
import { Button } from 'antd';
import generateId from '@/src/utils/id';
import Color from 'color';
import { graphic } from 'echarts/core';
import { colors } from './chart/default-config';
import Uniq from 'lodash/uniq';
import GroupBy from 'lodash/groupBy';
import Trim from 'lodash/trim';
import colorPalettes from './chart/chart-colors';
import { severityColors } from '@/src/design/theme';
import { Asset } from '../../pickers/AssetPicker';

export function buildColumns(data, widget, options) {
  const columnKeys = data.result ? Object.keys(data.result[0] || {}) : [];
  let widgetPropertiesColumns = ((widget.widgetProperties || {}).columns || []).length
    ? widget.widgetProperties.columns.filter((c) => columnKeys.includes(c.key) !== false)
    : columnKeys.map((key) => ({ key }));
  const missingKeys = columnKeys.filter(
    (c) => !widgetPropertiesColumns.find(({ key }) => key === c)
  );
  widgetPropertiesColumns = [...widgetPropertiesColumns, ...missingKeys.map((key) => ({ key }))];
  return widgetPropertiesColumns.map((column) => {
    // eslint-disable-next-line
    // debugger;
    return {
      title: column.key,
      dataIndex: column.key,
      key: column.key,
      ...(column || {}),
      ...((column.title || '').toLowerCase() === 'endpoint'
        ? {
            render: (text, record) => {
              // eslint-disable-next-line
              let { allOptions } = Asset.useAssets();
              let item = Array.from(allOptions.values())?.find(
                (i) => (i.filterLabel || i.label).toLowerCase() === (text || '').toLowerCase()
              );
              return text && typeof text === 'string' ? (
                item ? (
                  <Link to={`/inventory/endpoints/${item.value}`}>{text}</Link>
                ) : (
                  text
                )
              ) : (
                text
              );
            }
          }
        : {}),
      ...(['cve', 'affected endpoints'].includes((column.title || '').toLowerCase())
        ? {
            render: (text, record) => {
              return (
                <Button
                  type="link"
                  onClick={() => {
                    if (
                      (column.title || '').toLowerCase() === 'cve' &&
                      options &&
                      options.setCVEDetail
                    ) {
                      options.setCVEDetail({ cve: record.CVE || record.cve });
                    } else if (
                      (column.title || '').toLowerCase() === 'affected endpoints' &&
                      options &&
                      options.setAffectedEndpointsCVE
                    ) {
                      options.setAffectedEndpointsCVE({ cve: record.cve || record.CVE });
                    }
                  }}>
                  {text}
                </Button>
              );
            }
          }
        : {})
    };
  });
}

export default function buildResult(data, widget = {}) {
  if (widget.type === 'PieChart') {
    let categories = [];
    let series = [];
    if (data['x-axis'] && data['y-axis']) {
      categories = (data.result || []).map((item) => item[data['x-axis']]);
      series = [
        {
          name: data['y-axis'],
          data: (data.result || []).map((item, index) => ({
            name: item[data['x-axis']],
            value: item[data['y-axis']],
            itemStyle: buildColor({
              type: widget.type,
              widgetProperties: widget.widgetProperties,
              series: item,
              index
            })
          }))
        }
      ];
    }
    return {
      ...(categories.length > 0 ? { categories: categories } : {}),
      series
    };
  } else if (['AreaChart', 'LineChart', 'BarChart'].includes(widget.type)) {
    let categories = [];
    let series = [];
    if (data['x-axis'] && data['y-axis']) {
      if (data['x-axis'] === 'time') {
        series = [
          {
            name: data['y-axis'],
            data: (data.result || []).map((item) => [item[data['x-axis']], item[data['y-axis']]])
          }
        ];
      } else {
        categories = (data.result || []).map((item) => item[data['x-axis'].split(',')[0]]);
        series = [
          {
            name: data['y-axis'],
            data: (data.result || []).map((item) =>
              data['x-axis'] === 'severity'
                ? {
                    value: item[data['y-axis']],
                    itemStyle: {
                      color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                          offset: 0,
                          color: Color(severityColors[item.severity.toLowerCase()])
                            .alpha(1)
                            .toString()
                        },
                        {
                          offset: 1,
                          color: Color(severityColors[item.severity.toLowerCase()])
                            .alpha(
                              (widget.widgetProperties || {}).opacity !== undefined
                                ? (widget.widgetProperties || {}).opacity
                                : (widget.type || '').indexOf('Bar') >= 0
                                ? 0.3
                                : 1
                            )
                            .toString()
                        }
                      ]),
                      borderColor: severityColors[item.severity.toLowerCase()]
                    }
                  }
                : item[data['y-axis']]
            )
          }
        ];
      }
    }
    return {
      ...(categories.length > 0 ? { categories: categories } : {}),
      series
    };
  } else if ((widget.type || '').indexOf('Stacked') >= 0) {
    // stacked chart
    let categories = [];
    let series = [];
    if (data['x-axis'] && data['y-axis']) {
      if (data['x-axis'] === 'time') {
        series = [
          {
            name: data['y-axis'],
            data: (data.result || []).map((item) => [item[data['x-axis']], item[data['y-axis']]])
          }
        ];
      } else {
        if (data['x-axis'].indexOf(',') >= 0) {
          // stacking here
          const result = data.result || [];
          const groupColumns = data['x-axis'].split(',').map((c) => Trim(c));
          categories = Uniq(result.map((item) => item[groupColumns[0]]));
          const groupedResult = GroupBy(
            result,
            (item) => `${item[groupColumns[0]]}-${item[groupColumns[1]]}`
          );
          const uniqeSeriesNames = new Set();
          result.forEach((row) => {
            uniqeSeriesNames.add(row[groupColumns[1]]);
          });
          series = Array.from(uniqeSeriesNames).map((seriesName) => {
            return {
              name: seriesName,
              data: categories.map((category) =>
                groupedResult[`${category}-${seriesName}`]
                  ? (groupedResult[`${category}-${seriesName}`][0] || {})[data['y-axis']] || 0
                  : null
              )
            };
          });
        } else {
          categories = (data.result || []).map((item) => item[data['x-axis']]);
          series = [
            {
              name: data['y-axis'],
              data: (data.result || []).map((item) => item[data['y-axis']])
            }
          ];
        }
      }
    }
    return {
      ...(categories.length > 0 ? { categories: categories } : {}),
      series
    };
  } else if (widget.type === 'Grid') {
    const columns = buildColumns(data, widget);
    return {
      columns,
      data: (data.result || []).map((d) => ({ ...d, guid: generateId() }))
    };
  } else if (widget.type === 'Gauge') {
    const value = (data.result[0] || {})[data['x-axis']];
    return {
      data: value,
      style: {
        ...evaluateColor(value, widget.widgetProperties.colorConfig)
      }
    };
  } else if (widget.type === 'VulnerabilityMatrix') {
    const xAxisGrouping = GroupBy(data.result || [], data['x-axis']);
    const keys = ['> 90 days', '60-90 days', '30-60 days', '< 30 days'];
    let result = {};
    for (let i = 0; i < keys.length; i++) {
      const yAxisSplits = data['y-axis'].split(',');
      const grouping = GroupBy(xAxisGrouping[keys[i]] || [], yAxisSplits[0]);
      result[keys[i]] = Object.keys(grouping).reduce(
        (prev, key) => ({ ...prev, [key]: grouping[key][0][yAxisSplits[1] || 'total'] }),
        {}
      );
    }
    return result;
  }
  const columns = buildColumns(data, widget);
  return {
    columns,
    data: (data.result || []).map((d) => ({ ...d, guid: generateId() }))
  };
}

const types = {
  PieChart: 'pie',
  AreaChart: 'line',
  LineChart: 'line',
  BarChart: 'bar',
  StackedBarChart: 'bar',
  StackedAreaChart: 'line',
  StackedLineChart: 'line'
};

function evaluateColor(value, colorConfig = []) {
  const found = colorConfig.find((config) =>
    evaluateExpression(value, config.operator, config.value)
  );

  if (found) {
    return { background: found.color, color: found.textColor || 'var(--page-text-color)' };
  }
  return {};
}

export function evaluateExpression(op1, operator, op2) {
  switch (operator) {
    case 'gt':
      return op1 > op2;
    case 'lt':
      return op1 < op2;
    case 'lte':
      return op1 <= op2;
    case 'gte':
      return op1 >= op2;
    case 'eq':
      // eslint-disable-next-line
      return op1 == op2;
    case 'contain':
      return String(op1 || '').indexOf(String(op2 || '')) >= 0;

    default:
      break;
  }
}

function buildColor({ type, widgetProperties, index, series }) {
  return {
    ...(type.indexOf('Bar') >= 0 || type.indexOf('Pie') >= 0
      ? {
          borderWidth: 1,
          borderColor: series.color
            ? series.color
            : colorPalettes[widgetProperties.colorPalette]
            ? colorPalettes[widgetProperties.colorPalette][index]
            : colors[index]
        }
      : {}),
    color:
      // Color(
      //   series.color
      //     ? series.color
      //     : colorPalettes[widgetProperties.colorPalette]
      //     ? colorPalettes[widgetProperties.colorPalette][index]
      //     : colors[index]
      // )
      //   .alpha(widgetProperties.opacity ? widgetProperties.opacity : 1)
      //   .toString()
      new graphic.LinearGradient(0, 0, 0, 1, [
        {
          offset: 0,
          color: Color(
            series.color
              ? series.color
              : colorPalettes[widgetProperties.colorPalette]
              ? colorPalettes[widgetProperties.colorPalette][index]
              : colors[index]
          )
            .alpha(1)
            .toString()
        },
        {
          offset: 1,
          color: Color(
            series.color
              ? series.color
              : colorPalettes[widgetProperties.colorPalette]
              ? colorPalettes[widgetProperties.colorPalette][index]
              : colors[index]
          )
            .alpha(
              widgetProperties.opacity !== undefined
                ? widgetProperties.opacity
                : type.indexOf('Bar') >= 0
                ? 0.3
                : 1
            )
            .toString()
        }
      ])
  };
}

export function buildSeries(series, { type, isStacked, widgetProperties, dateTime, connectNulls }) {
  let chartType = types[type] || type;
  let guid = generateId();
  return series.map((s, index) => {
    return {
      ...s,
      type: chartType,
      smooth: true,
      symbol: 'none',
      connectNulls: connectNulls || false,
      animationDuration: 500,
      animationDurationUpdate: 200,
      ...(chartType === 'LineChart'
        ? {
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: 'rgba(0, 0, 0, 0.5)',
            //   },
            // },
            lineStyle: {
              width: widgetProperties.lineWidth || 2
            }
          }
        : {}),
      ...(isStacked ? { stack: guid } : {}),
      ...(type.indexOf('Area') >= 0 || type.indexOf('Bar') >= 0 || type.indexOf('Pie') >= 0
        ? {
            [type.indexOf('Area') >= 0 ? 'areaStyle' : 'itemStyle']: buildColor({
              type,
              widgetProperties,
              index,
              series: s
            })
          }
        : {})
    };
  });
}
