import { useState } from 'react';
import { Modal, Form, Input, Row, Col, Button } from 'antd';
import Merge from 'lodash/merge';
import SocketEventHandler from '../SocketEventHandler';

export default function AIBtn() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formItem, setFormItem] = useState({});
  const [eventKey, setEventKey] = useState(0);

  function handleFormSubmit() {
    setEventKey((key) => key + 1);
  }

  return (
    <>
      <Button
        type="primary"
        size="medium"
        className="gradient-btn"
        onClick={() => setIsModalOpen(true)}>
        AI Help
      </Button>
      {isModalOpen ? (
        <Modal
          open={Boolean(isModalOpen)}
          title={`AI Help`}
          onCancel={() => setIsModalOpen(false)}
          destroyOnClose={true}
          footer={null}>
          <Form
            layout="vertical"
            className="h-full"
            onValuesChange={(values) => {
              setFormItem(Merge({ ...formItem }, { ...values }));
            }}
            onFinish={handleFormSubmit}
            initialValues={formItem}>
            <Row gutter={32} className="items-center">
              <Col span={24}>
                <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                  <Input placeholder="Type ex. script" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="Query" name="message" rules={[{ required: true }]}>
                  <Input.TextArea placeholder="Type ex. Write me a worklet for Windows that uninstall Adobe Flash." />
                </Form.Item>
              </Col>
              <Col span={24} className="text-right">
                <Button htmlType="submit">Send</Button>
              </Col>
            </Row>
          </Form>
          <div>
            {eventKey > 0 ? (
              <SocketEventHandler event="ai_request" context={formItem}>
                {(payload) =>
                  payload.error ? (
                    <div className="flex flex-col justify-center items-center flex-1">
                      <h2 className="text-danger text-center">{payload.error}</h2>
                    </div>
                  ) : (
                    <pre>{JSON.stringify(payload, undefined, 4)}</pre>
                  )
                }
              </SocketEventHandler>
            ) : null}
          </div>
        </Modal>
      ) : null}
    </>
  );
}
