import AppLayout from '@/src/layouts/Layout.jsx';
import React, { lazy } from 'react';
import { Route, Navigate } from 'react-router-dom';
import FIMEvents from './views/FIMEvents';

const FimLayout = lazy(() => import(/* webpackChunkName: "fim" */ './layout/FimLayout'));

const Configuration = lazy(() =>
  import(/* webpackChunkName: "fim" */ './views/ListConfigurations')
);
/**
 *
 * you can pass any config during init and those config can become prop for any of the route component
 */

export default function getRoutes(config) {
  return (
    <Route path="/fim" key="fim" element={<AppLayout />}>
      <Route element={<FimLayout />}>
        {/* if no route is defined navigate to about route */}
        <Route index element={<Navigate replace to="events" />}></Route>
        <Route path="events" element={<FIMEvents />} />
        <Route path="configuration" element={<Configuration />}></Route>
      </Route>
    </Route>
  );
}
