import { Modal, Form, Input } from 'antd';

export default function TestMailServerConfig({ open, onCreate, onCancel }) {
  const [form] = Form.useForm();
  return (
    <Modal
      open={open}
      title="Test Mail Server Configuration"
      okText="Test"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}>
      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item name="target" label="Target Email" rules={[{ required: true, type: 'email' }]}>
          <Input type="email" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
