import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import Icon from './Icon';
// import PermissionChecker from './PermissionChecker';
import constants from '../constants/index';
import { Permissions } from './Permissions';
import { Branding } from './Branding';
import { useAuth } from '../hooks/auth';

const { Sider } = Layout;

const items = [
  {
    key: 'dashboard',
    label: 'Dashboard',
    icon: <Icon className="mr-2 text-lg" name="dashboard" style={{ fontSize: '1.5rem' }} />,
    permissions: [constants.View_Dashboard]
  },
  {
    key: 'inventory',
    label: 'Inventory',
    icon: <Icon className="mr-2 text-lg" name="inventory" style={{ fontSize: '1.5rem' }} />,
    permissions: [constants.View_Inventory],
    children: [
      {
        key: 'endpoints',
        label: 'Endpoints'
      },
      {
        key: 'network-devices',
        label: 'Network Devices'
      },
      {
        key: 'softwares',
        label: 'SBOM'
      },
      {
        key: 'browsers',
        label: 'Browsers'
      },
      {
        key: 'software-metering',
        label: 'Software Metering'
      },
      {
        key: 'license-management',
        label: 'License Management'
      }
    ]
  },
  {
    key: 'compliance',
    label: 'Compliance',
    icon: <Icon className="mr-2 text-lg" name="compliance" style={{ fontSize: '1.5rem' }} />,
    permissions: [constants.View_Compliance]
  },
  {
    key: 'vulnerability',
    label: 'Vulnerability',
    icon: <Icon className="mr-2 text-lg" name="vulnerability" style={{ fontSize: '1.5rem' }} />,
    permissions: [constants.View_Vulnerability, constants.View_Manage_Exceptions],
    children: [
      {
        key: 'all',
        label: 'Vulnerabilities',
        permissions: [constants.View_Vulnerability]
      },
      {
        key: 'manage-exceptions',
        label: 'Manage Exceptions',
        // icon: <Icon className="mr-2 text-lg" name="vulnerability" style={{ fontSize: '1.5rem' }} />,
        permissions: [constants.View_Manage_Exceptions]
      }
    ]
  },
  {
    key: 'fim',
    label: 'FIM',
    icon: <Icon className="mr-2 text-lg" name="fim" style={{ fontSize: '1.5rem' }} />,
    permissions: [constants.View_FIM],
    children: [
      {
        key: 'events',
        label: 'Events'
      },
      {
        key: 'configuration',
        label: 'Configuration'
      }
    ]
  },
  {
    key: 'patch',
    label: 'Patch',
    icon: <Icon className="mr-2 text-lg" name="patch" style={{ fontSize: '1.5rem' }} />,
    permissions: [constants.View_Patch],
    children: [
      {
        key: 'patches',
        label: 'Patches'
        // icon: <Icon className="mr-2 text-lg" name="vulnerability" style={{ fontSize: '1.5rem' }} />,
      },
      {
        key: 'patch-deployments',
        label: 'Patch Deployments'
        // icon: <Icon className="mr-2 text-lg" name="vulnerability" style={{ fontSize: '1.5rem' }} />,
      },
      {
        key: 'auto-patch-test',
        label: 'Patch Test & Approve'
        // icon: <Icon className="mr-2 text-lg" name="vulnerability" style={{ fontSize: '1.5rem' }} />,
      },
      {
        key: 'auto-patch-deployment',
        label: 'Zero Touch Deployment'
        // icon: <Icon className="mr-2 text-lg" name="vulnerability" style={{ fontSize: '1.5rem' }} />,
      }
    ]
  },
  {
    key: 'automation',
    label: 'Automation',
    icon: <Icon className="mr-2 text-lg" name="automation" style={{ fontSize: '1.5rem' }} />,
    permissions: [constants.View_Software, constants.View_Configuration],
    children: [
      {
        key: 'software-automation',
        label: 'Software Automation',
        permissions: [constants.View_Software]
        // icon: <Icon className="mr-2 text-lg" name="vulnerability" style={{ fontSize: '1.5rem' }} />,
      },
      {
        key: 'configuration-automation',
        label: 'Configuration Automation',
        permissions: [constants.View_Configuration]
        // icon: <Icon className="mr-2 text-lg" name="vulnerability" style={{ fontSize: '1.5rem' }} />,
      },
      {
        key: 'app-control',
        label: 'Application Control',
        permissions: [constants.View_Configuration]
        // icon: <Icon className="mr-2 text-lg" name="vulnerability" style={{ fontSize: '1.5rem' }} />,
      }
    ]
    // permissions: [constants.View_Investigate]
  },
  {
    key: 'reports',
    label: 'Reports',
    icon: <Icon className="mr-2 text-lg" name="report" style={{ fontSize: '1.5rem' }} />,
    permissions: [constants.View_Report]
  },
  // {
  //   key: 'rootkit',
  //   label: 'Rootkit',
  //   icon: <Icon className="mr-2 text-lg" name="rootkit" style={{ fontSize: '1.5rem' }} />,
  //   permissions: [constants.View_Root_Kit],
  //   children: [
  //     {
  //       key: 'alerts',
  //       label: 'Alerts'
  //     }
  //   ]
  // },
  {
    key: 'alerts',
    label: 'Alerts',
    icon: <Icon className="mr-2 text-lg" name="alert" style={{ fontSize: '1.5rem' }} />,
    permissions: [constants.View_Alert]
  },
  {
    key: 'okr',
    label: 'OKR',
    icon: <Icon className="mr-2 text-lg" name="okr" style={{ fontSize: '1.5rem' }} />,
    permissions: [constants.View_OKR]
  }

  // {
  //   key: 'audit',
  //   label: 'Audit',
  //   icon: <AuditOutlined className="mr-2 text-lg" name="alert" style={{ fontSize: '1.5rem' }} />,
  //   permissions: [constants.View_Audit]
  // },
  // {
  //   key: '7',
  //   label: 'Notification',
  //   icon: <Icon className="mr-2 text-lg" name="notification" style={{ fontSize: '1.5rem' }} />
  // },
  // {
  //   key: 'settings',
  //   label: 'Settings',
  //   permissions: [constants.View_Settings, constants.View_Quick_Checks],
  //   icon: <Icon className="mr-2 text-lg" name="settings" style={{ fontSize: '1.5rem' }} />
  // }
];

export default function Navbar({ setCollapsed, ...props }) {
  const { branding } = Branding.useBranding();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const { token } = useAuth();
  const [currentMenuItems, setCurrentMenuItems] = useState(items);
  // eslint-disable-next-line
  const [openKeys, setOpenKeys] = useState([]);

  const { hasPermission, permissions } = Permissions.usePermission();

  const navigate = useNavigate();

  const route = useLocation();

  useEffect(() => {
    const pathParts = route.pathname.split('/').filter(Boolean);
    setSelectedKeys([pathParts[0]]);
    setOpenKeys([pathParts[0]]);
  }, [route]);

  useEffect(() => {
    function filterMenuItems(items = []) {
      if (items.length) {
        return items
          .filter((i) => hasPermission(i.permissions))
          .map((i) => ({ ...i, children: filterMenuItems(i.children) }));
      }
      return null;
    }
    setCurrentMenuItems(filterMenuItems(items));
  }, [permissions, hasPermission]);

  function handleOnSelect({ keyPath }) {
    navigate(`/${keyPath.reverse().join('/')}`);
  }

  return (
    <Sider
      {...props}
      width={300}
      onMouseEnter={() => setCollapsed(false)}
      onMouseLeave={() => setCollapsed(true)}
      className="relative shadow-lg overflow-y-auto"
      trigger={null}
      collapsible
      collapsed={props.collapsed}
      theme="light"
      style={{ paddingTop: '16px' }}>
      {/* onCollapse={(value) => props.setCollapsed(value)}> */}
      {/* <div className="flex items-center justify-around" style={{ height: '90px' }}>
        <div className="">
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger mx-1 text-lg',
            onClick: () => setCollapsed(!collapsed)
          })}
        </div>
      </div> */}
      <div className="mb-4 text-center">
        {props.collapsed ? (
          <img style={{ height: '37px' }} src={'/images/logo-small.svg'} alt="logo" />
        ) : (
          <img
            style={{ width: '140px' }}
            src={
              branding.ref
                ? `/api/download?id=${
                    branding.ref || branding.response
                    // eslint-disable-next-line
                  }&mid=${token.access_token}`
                : '/images/logo.svg'
            }
            alt="logo"
          />
        )}
      </div>

      <Menu
        theme="light"
        mode="inline"
        key={selectedKeys.join(',')}
        defaultSelectedKeys={selectedKeys}
        items={currentMenuItems}
        selectedKeys={selectedKeys}
        onSelect={handleOnSelect}
        onOpenChange={setOpenKeys}
      />
    </Sider>
  );
}
